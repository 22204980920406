<template>
	<v-sheet flat>
		<h2>Projects</h2>
		<p>
			The following list includes all past, present and future projects
			assigned to you. To view more information, click on "view" for the
			relevant row.
		</p>
		<v-text-field label="Search" append-icon="mdi-magnify" v-model="search"></v-text-field>
		<v-data-table :search="search" :headers="headers" :items="items">
			<template v-slot:item.status="{ item }">
				<dashboard-chip
					:value="mwutils.prettyPrint(item.project_status, 'TITLE')"
				></dashboard-chip>
			</template>
			<template v-slot:item.dates="{ item }">
				<project-dates :value="item.id"></project-dates>
			</template>
			<template v-slot:item.btn="{ item }">
				<v-btn color="primary" small router-link :to="link(item.id)">
					View Project
				</v-btn>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<script>
import DashboardChip from "@c/dashboard/DashboardChip";
import ProjectDates from "@c/dashboard/ProjectDates";
export default {
	name: "Projects",
	data: () => {
		return {
			search: "",
			headers: [
				{ value: "client_name", text: "Name" },
				{ value: "status", text: "Status" },
				{ value: "description", text: "Description" },
				{ value: "dates", text: "Dates" },
				{ value: "btn", text: "" },
			],
		};
	},
	computed: {
		projects() {
			return this.$store.state.projects.data;
		},
		items() {
			return Object.values(this.projects);
		},
	},
	components: {
		ProjectDates,
		DashboardChip,
	},
	created() {
		this.$store.dispatch("projects/fetchAll");
	},
	methods: {
		link(id) {
			let project = this.projects[id];
			if (project.incentive_only) {
				return `/incentives/${project.id}/view`;
			} else {
				return `/projects/${project.id}/sessions`;
			}
		},
	},
};
</script>
